import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Divider, styled, Tooltip } from "@material-ui/core";
import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as AnonymizationIcon } from "../../../../assets/images/icons/anonymization-ai.svg";
import { ReactComponent as CleanReadIcon } from "../../../../assets/images/icons/cleanread.svg";
import { ReactComponent as MachineMadeIcon } from "../../../../assets/images/icons/machine-made.svg";

import AmberPrimaryButton from "../../../buttons/AmberPrimaryButton";
import { withTranslate } from "react-redux-multilingual";
import AmberOutlinedButton from "../../../buttons/AmberOutlinedButton";
import { apiClient } from "../../../../apiClient";
import { saveAs } from "file-saver";
import { getExportFilename } from "../ExportApi";
import { AMBER_PRIMARY } from "../../../ui/mui5/colors";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { CircularProgress } from "@mui/material";
import {connect} from "react-redux";

const GreenDivider = styled(Divider)(() => ({
  backgroundColor: AMBER_PRIMARY,
}));

function ExportTextActions(props) {
  const {
    translate,
    handleExport,
    transcriptStatus,
    handleDownloadFile,
    isVideo,
    isCaptions = false,
    onClose,
    accountData
  } = props;

  const isAnonymizationCleanReadShown = (transcriptStatus.data.nrAudioSeconds / 60) < 20;
  const [aiContentLoading, setAiContentLoading] = useState(false);
  const orgAIEnabled = accountData.genAIEnabled;

  // when input is segments than response is JSON
  const endpointCall = (transcriptionType, featureType) => {
    setAiContentLoading(true);
    apiClient.post(`/ai/transform/${transcriptionType}/${featureType}`, {
        input: "jobId",
        jobId: transcriptStatus?.data?.job?.id,
        outputFormat: "docx",
    },{
      responseType: 'arraybuffer',
    }).then(response => {
      const blob = new Blob([response.data], { type: "application/octet-stream" });
      saveAs(blob, getExportFilename(transcriptStatus.data.audioDisplayFileName, 'docx'));
      setAiContentLoading(false);
      onClose();
    }).catch(error => {
      console.trace({error});
      setAiContentLoading(false);
    });
  }

  const exportSummary = () => {
    endpointCall("transcription", "summarization");
  }

  const exportAnonymization = () => {
    endpointCall("transcription", "anonymization");
  }

  const exportCleanRead = () => {
    endpointCall("transcription", "cleanread");
  }

  return (
    <>
    {!aiContentLoading && 
    (<Box>
      <Box 
        display="flex"
        justifyContent={isCaptions ? "end" : "space-between"}
        alignItems="center"
        mt={1}
        mb={4.25}
        ml={4.5}
        mr={4.5}
      >
        {!isCaptions && <AmberOutlinedButton
          text={
            isVideo
              ? translate("EDITOR.EXPORT.downloadVideo")
              : translate("EDITOR.EXPORT.downloadFile")
          }
          onClick={handleDownloadFile}
          testid="export-text-dialog-download-btn"
        />}       
        <AmberPrimaryButton
          text={translate("EDITOR.EXPORT.title")}
          onClick={handleExport}
          testid="export-text-dialog-export-btn"
        />
      </Box>

      {!isCaptions && 
      <Box padding="16px 36px">
        <GreenDivider />
        <Box display="flex" alignItems="center" height="60px">
          <MachineMadeIcon style={{ fill: '#2C846A', marginRight: '-7px', marginTop: '-7px' }} />
          <Box fontSize={18} fontWeight={1000} padding="15px" color="black">
          {translate("EDITOR.EXPORT.intelligentExport")}
          </Box>
          <Box
            fontWeight={1000}
            padding="10px"
            color="black"
            marginLeft="-15px"
            marginTop="10px"
          >
            <Tooltip title={translate('EDITOR.EXPORT.hint')} arrow>
              <InfoIcon sx={{ color: 'custom.infoMessage', cursor: 'pointer' }} />
            </Tooltip>
          </Box>
          {orgAIEnabled && (
            <Box
                fontSize={12}
                fontWeight={800}
                color="white"
                textAlign="center"
                bgcolor="gray"
                borderRadius="4px"
                width="160px"
                height="20px"
                lineHeight="20px"
            >
              Disabled by Organization
            </Box>
          )}
        </Box>
        {orgAIEnabled !== false && (
        <Box
          display="flex"
          justifyContent="space-evenly"
        >
          <AmberOutlinedButton
            text={translate("EDITOR.EXPORT.summary")}
            // tooltipText="Generate a summary of your transcript"
            tooltipText="EDITOR.EXPORT.summaryTooltip"
            iconComponent={<ImportContactsIcon style={{ fill: AMBER_PRIMARY, width: "25px", height: "25px", marginBottom: "3px" }} />}
            onClick={exportSummary}
            style={{ height: "35px", width: "170px", margin: "0 3px", display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <CleanReadIcon />
          </AmberOutlinedButton>
          {isAnonymizationCleanReadShown &&
          <AmberOutlinedButton
          text={translate("EDITOR.EXPORT.anonymization")}
            onClick={exportAnonymization}
            // tooltipText="Anonymize all personal-related data (names, addresses, etc.) of your transcription."
            tooltipText="EDITOR.EXPORT.anonymizationTooltip"
            iconComponent={<AnonymizationIcon style={{ fill: AMBER_PRIMARY, width: "27px", height: "27px", marginTop: "3px" }} />}
            style={{ height: "35px", width: "170px", margin: "0 3px" }}
          />
          }
          {isAnonymizationCleanReadShown &&
          <AmberOutlinedButton
          text={translate("EDITOR.EXPORT.cleanRead")}
            onClick={exportCleanRead}
            // tooltipText="Generate a transcript which is edited for easy readability"
            tooltipText="EDITOR.EXPORT.cleanReadTooltip"
            iconComponent={<CleanReadIcon style={{ fill: AMBER_PRIMARY, width: "27px", height: "27px", marginTop: "3px" }} />}
            style={{ height: "35px", width: "170px", margin: "0 3px", display: "flex", alignItems: "center", justifyContent: "center" }}
          />
          }
        </Box>
        )}
      </Box>
      }
    </Box>

    )}
    {aiContentLoading && (
      <Box position="absolute" height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" bgcolor="white">
        <CircularProgress size={100} />
      </Box>
    )}
    </>
  );
}

const mapStateToProps = (state) => ({
  accountData: state.account.data,
});

ExportTextActions.propTypes = {
  handleExport: PropTypes.func.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
  isVideo: PropTypes.bool.isRequired,
  isCaptions: PropTypes.bool,
};

ExportTextActions.defaultProps = {
  isCaptions: false,
};

export default connect(mapStateToProps)(withTranslate(ExportTextActions));
